h1,
.h2 {
    font-size: 1.3rem;
}

h2,
.h2 {
    font-size: 1.1rem;
}

.lead {
    font-size: 1rem;
}

.section1 {
    background-image: url(../img/murder-party.jpg);
    background-size: cover;
    background-position: bottom left;
}

.section2 {
    background-image: url(../img/chateau-montfleury.jpg);
    background-size: cover;
}
.section3{
  // background-image: url(../img/soiree-intrigue.jpg);
  //   background-position: bottom left;
  //   background-size: 40%;
  //   background-repeat: no-repeat;
  .lead {
    font-size: 0.8rem;
}
}

.btn-scrolldown {
    position: absolute;
    bottom: 3%;
    left: 50%;
}

.btn-inscription {
    margin-bottom: 15px;
}
.event-wrapper{
  margin-top: 3%;
}
// .block-event {
//     border-top: #999 1px solid;
//     border-bottom: #999 1px solid;

// }

.facebook-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.facebook-text {
    color: #7D634C;
    margin-left: 15px;
}

.contact {
    color: #7D634C;
}

iframe.map-event {
    width: 100% !important;
    height: 100% !important;
}
@media screen and (max-width: 544px) {
.btn-lg, .btn-group-lg > .btn {
    padding: 0.35rem 1rem;
    font-size: 1rem;
    border-radius: 0.3rem;
}
}

@media screen and (min-width: 544px) {
    h1,
    .h2 {
        font-size: 1.8rem;
    }
    h2,
    .h2 {
        font-size: 1.6rem;
    }
    .lead {
        font-size: 1.45rem;
    }
    .section3 {
        background-image: url(../img/soiree-intrigue.jpg);
        background-position: bottom left;
        background-size: 100%;
        background-repeat: no-repeat;
          .lead {
    font-size: 1.45rem;
}
    }
    .block-event {
    border-top: #999 1px solid;
    border-bottom: #999 1px solid;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.btn-inscription {
    margin-top: 30px;
    margin-bottom: 30px;
}
}

@media screen and (min-width: 992px) {
    h1,
    .h2 {
        font-size: 2.5rem;
    }
    h2,
    .h2 {
        font-size: 1.6rem;
    }
    .lead {
        font-size: 1.45rem;
    }
    .section3 {
        background-size: 50%;
    }
}
